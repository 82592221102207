export default {
  'default': {
    'territorial': [
      'country',
      'province',
      'canton',
      'parish',
      'geographic_region',
      'biome',

    ],
    'land': [
      'protected_natural_area',
      'sub_national_protected_natural_area',
      'ramsar_wetland',
      'biosphere_reserve',
      'protected_forest',
      'indigenous_territory',
      'protection_zone',
      'socio_bosque_program',
      'hidrological_protection_area',
      'connectivity_corridor',
      'biogeographic_unit',
      'marine_reserve',
      'conservation_and_sustainable_private_use_area',
      'conservation_and_sustainable_community_use_area',
      'water_recharge_zone',
      'state_forest_patrimony',
      'glaciers',
      'river_basin_district',
      'ecosystem',
      'watershed_otto_1',
      'watershed_otto_2',
      'watershed_otto_3',
      'watershed_otto_4',
      'acus',
      'ecociencia',
    ]
  },
  'simplified': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ]
  },
  'infrastructure': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ]
  },
  'fire': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ],
    'land': [
      'settlements',
      'quilombos',
      'indigenous_territories',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
      'municipality_protected_area_integral_protection',
      'municipality_protected_area_sustainable_use',
    ]
  },
  'soil': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
    ]
  },
  'sentinel': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
    ],
    'land': [
      'settlements',
      'quilombos',
      'indigenous_territories',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
    ]
  },
  'degradation': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ],
    'land': [
      'indigenous_territories',
      'municipality_protected_area_integral_protection',
      'municipality_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
    ]
  },
};
