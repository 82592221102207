export default [
  {
    index: 0,
    key: "country",
    labelKey: "country",
    color: "#FF0000",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'CONALI, Adaptado por EcoCiencia}' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/nivel-politico-1.zip'
  },
  {
    index: 1,
    key: "biome",
    labelKey: "biome",
    color: "#00FF00",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/bioma-pais.zip'
  },
  {
    index: 2,
    key: "province",
    labelKey: "province",
    color: "#0000FF",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'CONALI, Adaptado por EcoCiencia}' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/nivel-politico-2.zip'
  },
  {
    index: 3,
    key: "canton",
    labelKey: "canton",
    color: "#FFFF00",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'CONALI, Adaptado por EcoCiencia}' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/nivel-politico-3.zip'
  },
  {
    index: 4,
    key: "parish",
    labelKey: "parish",
    color: "#FF00FF",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'CONALI, Adaptado por EcoCiencia}' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/nivel-politico-4.zip'
  },
  {
    index: 5,
    key: "geographic_region",
    labelKey: "geographic_region",
    color: "#00FFFF",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'CONALI, Adaptado por EcoCiencia}' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/region-geografica.zip'
  },
  {
    index: 6,
    key: "protected_natural_area",
    labelKey: "protected_natural_area",
    color: "#FF8C00",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/nacionales.zip'
  },
  {
    index: 7,
    key: "sub_national_protected_natural_area",
    labelKey: "sub_national_protected_natural_area",
    color: "#008B8B",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/departamentales.zip'
  },
  {
    index: 8,
    key: "ramsar_wetland",
    labelKey: "ramsar_wetland",
    color: "#8A2BE2",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/ramsar.zip'
  },
  {
    index: 9,
    key: "biosphere_reserve",
    labelKey: "biosphere_reserve",
    color: "#FF4500",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/reserva-biosfera.zip'
  },
  {
    index: 10,
    key: "protected_forest",
    labelKey: "protected_forest",
    color: "#008000",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/bosques.zip'
  },
  {
    index: 11,
    key: "indigenous_territory",
    labelKey: "indigenous_territory",
    color: "#8B0000",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/tis.zip'
  },
  {
    index: 12,
    key: "protection_zone",
    labelKey: "protection_zone",
    color: "#000080",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/zonas_proteccion_amazonica.zip'
  },
  {
    index: 13,
    key: "socio_bosque_program",
    labelKey: "socio_bosque_program",
    color: "#808000",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/socio-bosque.zip'
  },
  {
    index: 14,
    key: "hidrological_protection_area",
    labelKey: "hidrological_protection_area",
    color: "#8B4513",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/proteccion-hidrica.zip'
  },
  {
    index: 15,
    key: "connectivity_corridor",
    labelKey: "connectivity_corridor",
    color: "#4B0082",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/corredores-conservacion.zip'
  },
  {
    index: 16,
    key: "biogeographic_unit",
    labelKey: "biogeographic_unit",
    color: "#2F4F4F",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/unidad-biogeografica.zip'
  },
  {
    index: 17,
    key: "marine_reserve",
    labelKey: "marine_reserve",
    color: "#800080",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/reserva-marina.zip'
  },
  {
    index: 18,
    key: "conservation_and_sustainable_use_area",
    labelKey: "conservation_and_sustainable_use_area",
    color: "#ADFF2F",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/area-conservacion-uso-sostenible.zip'
  },
  {
    index: 19,
    key: "water_recharge_zona",
    labelKey: "water_recharge_zona",
    color: "#FFD700",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/recarga-hidrica.zip'
  },
  {
    index: 20,
    key: "state_forest_patrimony",
    labelKey: "state_forest_patrimony",
    color: "#228B22",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/patrimonio-forestal.zip'
  },
  {
    index: 21,
    key: "river_basin_district",
    labelKey: "river_basin_district",
    color: "#9932CC",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/demarcacion-hidrografica.zip'
  },
  {
    index: 22,
    key: "ecosystem",
    labelKey: "ecosystem",
    color: "#00CED1",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/ecosistemas.zip'
  },
  {
    index: 23,
    key: "watershed_otto_1",
    labelKey: "watershed_otto_1",
    color: "#1E90FF",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/unidad-hidrografica-1.zip'
  },
  {
    index: 24,
    key: "watershed_otto_2",
    labelKey: "watershed_otto_2",
    color: "#FF6347",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/unidad-hidrografica-2.zip'
  },
  {
    index: 25,
    key: "watershed_otto_3",
    labelKey: "watershed_otto_3",
    color: "#87CEEB",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/unidad-hidrografica-3.zip'
  },
  {
    index: 26,
    key: "watershed_otto_4",
    labelKey: "watershed_otto_4",
    color: "#008B8B",
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MAATE, adaptado por EcoCiencia' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/ecuador/download/layers/unidad-hidrografica-4.zip'
  }
];
