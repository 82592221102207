import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_CLASS_TREE_DATA } from '../graphql/queries/general';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/amazonia';

const customSubmoduleProps = {
  coverage_main: {
    year: 'activeYear',
    legend: 'activeClassTreeOptionValue',
  }
};

export default function useGenericMapStatistics(params) {
  const {
    activeClassTreeNodeIds,
    activeClassTreeOptionValue,
    activeSubmodule,
    crossingTerritories,
    territories,
    ruralPropertyCode,
    groupType,
    geometry,
    yearRange,
    levels,
  } = params;
  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: activeClassTreeOptionValue,
    },
  });

  const classTreeNodes = _.get(
    classTreeData,
    'classTreeByKey[0].mvClassTreeLevelsUi'
  );

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const customProps = customSubmoduleProps[activeSubmodule] || {};
  const parsedCustomParams = _.reduce(
    customProps,
    (obj, paramFormatter, paramKey) => {
      if (_.isFunction(paramFormatter)) {
        obj[paramKey] = paramFormatter(params);
      } else if (_.isString(paramFormatter)) {
        obj[paramKey] = _.get(params, paramFormatter);
      } else {
        return obj;
      }
      return obj;
    },
    {}
  );

  const crossingTerritoriesIds = _.map(crossingTerritories, 'id');
  let parsedParams = {
    initiative: 'ecuador',
    pixelValues: _.filter(
      _.map(activeClassTreeNodeIds, (nodeId) =>
        _.get(_.find(classTreeNodes, { id: nodeId }), 'pixelValue')
      ),
      _.isNumber
    ),
    territoryIds: _.map(territories, 'id'),
    ...parsedCustomParams,
  };

  if (!_.isEmpty(crossingTerritoriesIds)) {
    parsedParams['crossedTerritoryIds'] = crossingTerritoriesIds;
  }

  if (groupType === 'car' && ruralPropertyCode) {
    parsedParams['propertyCode'] = ruralPropertyCode;
  }

  let statisticsParams = {
    initiative: 'ecuador',
    fromYear: 1985,
    toYear: parsedParams.year,
    pixelValues: parsedParams.pixelValues,
    territoryIds: parsedParams.territoryIds,
    crossedTerritoryIds: parsedParams.crossedTerritoryIds,
    legend: parsedParams.legend,
  };
  let urlPath = '/statistics/coverage_main';

  if (parsedParams.propertyCode) {
    urlPath = '/statistics/coverage_main/property';
    statisticsParams = {
      ..._.omit(statisticsParams, ['territoryIds', 'crossedTerritoryIds']),
      propertyCode: parsedParams.propertyCode,
    };
  }

  if (geometry) {
    urlPath = '/statistics/coverage_main/geometry';
    statisticsParams = {
      ..._.omit(statisticsParams, [
        'territoryIds',
        'crossedTerritoryIds',
        'propertyCode',
      ]),
      geometryCoordinates: JSON.stringify(
        _.get(geometry, 'geometry.coordinates')
      ),
    };
  }

  if (activeSubmodule === 'coverage_changes') {
    urlPath = '/statistics/coverage_changes';
    const [fromYear, toYear] = _.split(yearRange, '-');
    statisticsParams = {
      ...(_.pick(statisticsParams, [
        'territoryIds',
        'crossedTerritoryIds',
        'initiative'
      ])),
      fromYear,
      toYear,
      levels
    };
  }

  const statisticsUrl = `${API_URL}${urlPath}?${qs.stringify(statisticsParams, {
    arrayFormat: 'repeat',
  })}`;

  useEffect(() => {
    if (
      _.includes(statisticsUrl, 'territoryIds') && (
        _.includes(statisticsUrl, 'pixelValues') ||
        (activeSubmodule === 'coverage_changes' && _.includes(statisticsUrl, 'levels'))
      )
    ) {
      setLoading(true);
      fetch(statisticsUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        });
    }
  }, [statisticsUrl]);

  return {
    loading,
    data,
    pixelValues: parsedParams.pixelValues,
  };
}
